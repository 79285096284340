import { useEffect, useState } from "react";
import Web3 from "web3";
import contract from "../contracts/CL_V1.json";
import BackOffice from "./BackOffice";
import MinterWrapper from "./minter.style.js";

const keccak256 = require("keccak256");
const _magicWord = "Raspberry";
const imgSrc =
  "https://city--ladies.s3.ap-northeast-2.amazonaws.com/city-ladies_minting-card.gif";
const contractAddress = contract.address;

const initialInfoState = {
  connected: false,
  status: null,
  account: null,
  web3: null,
  contract: null,
  address: null,
  contractJSON: null,
};

const initialMintState = {
  loading: false,
  status: `Mint your ${contract.name}`,
  amount: 1,
  supply: "0",
  cost: "0",
};

const initialOwnableState = {
  owner: "",
  airdropedAmount: "0",
  maximumAirdropAmount: "0",
  baseURI: "",
  isPaused: false,
  isRandomArt: false,
  magicWord: _magicWord,
  keccak256MagicWord: `0x${keccak256(_magicWord).toString("hex")}`,
  getMagicWord: "",
  isMatchedMagicWord: false,
  balance: "0"
};

function Minter() {
  const [info, setInfo] = useState(initialInfoState);
  const [mintInfo, setMintInfo] = useState(initialMintState);
  const [ownerState, setOwnerState] = useState(initialOwnableState);

  const init = async (_request, _contractJSON) => {
    if (window.ethereum?.isMetaMask) {
      try {
        const accounts = await window.ethereum.request({
          method: _request,
        });
        const networkId = await window.ethereum.request({
          method: "net_version",
        });
        if (parseInt(networkId, 10) === _contractJSON.chain_id) {
          let web3 = new Web3(window.ethereum);
          setInfo((prevState) => ({
            ...prevState,
            connected: true,
            status: null,
            account: accounts[0],
            web3: web3,
            contract: new web3.eth.Contract(
              _contractJSON.abi,
              _contractJSON.address
            ),
            contractJSON: _contractJSON,
          }));
        } else {
          setInfo(() => ({
            ...initialInfoState,
            status: `Change network to ${_contractJSON.chain}.`,
          }));
        }
      } catch (err) {
        setInfo(() => ({
          ...initialInfoState,
        }));
        console.error(err.message);
      }
    } else {
      setInfo(() => ({
        ...initialInfoState,
        status: "Please install metamask.",
      }));
    }
  };

  const initListeners = () => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", () => {
        window.location.reload();
      });
      window.ethereum.on("chainChanged", () => {
        window.location.reload();
      });
    }
  };

  const getSupply = async () => {
    let err, result;
    await info.contract.methods
      .totalSupply()
      .call({ from: info.account }, (e, r) => {
        err = e;
        result = r;
      });
    const consoleMessage = `getSupply - Err: ${err} - Result: ${result}`;
    if (result) {
      setMintInfo((prevState) => ({
        ...prevState,
        supply: result,
      }));
      console.warn(consoleMessage);
    } else {
      setMintInfo((prevState) => ({
        ...prevState,
        supply: 0,
      }));
    }
  };

  const getPrice = async () => {
    let err, result;
    await info.contract.methods
      .getPrice()
      .call({ from: info.account }, (e, r) => {
        err = e;
        result = r;
      });
    const consoleMessage = `getPrice - Err: ${err} - Result: ${result}`;
    if (result) {
      setMintInfo((prevState) => ({
        ...prevState,
        cost: result,
      }));
    } else {
      setMintInfo((prevState) => ({
        ...prevState,
        cost: "0",
      }));
      console.warn(consoleMessage);
    }
  };

  const getOwner = async () => {
    let err, result;
    await info.contract.methods.owner().call({ from: info.account }, (e, r) => {
      err = e;
      result = r.toLowerCase();
    });
    const consoleMessage = `getOwner - Err: ${err} - Result: ${result}`;
    if (result) {
      setOwnerState((prevState) => ({
        ...prevState,
        owner: result,
      }));
    } else {
      setOwnerState((prevState) => ({
        ...prevState,
        owner: "",
      }));
      console.warn(consoleMessage);
    }
  };

  const getIsPaused = async () => {
    let err, result;
    await info.contract.methods
      .getIsPaused()
      .call({ from: info.account }, (e, r) => {
        err = e;
        result = r;
      });
    const consoleMessage = `getIsPaused - Err: ${err} - Result: ${result}`;
    if (result) {
      setOwnerState((prevState) => ({
        ...prevState,
        isPaused: result,
      }));
    } else {
      setOwnerState((prevState) => ({
        ...prevState,
        isPaused: false,
      }));
      console.warn(consoleMessage);
    }
  };

  const getIsRandomArt = async () => {
    let err, result;
    await info.contract.methods
      .getIsRandomArt()
      .call({ from: info.account }, (e, r) => {
        err = e;
        result = r;
      });
    const consoleMessage = `getIsRandomArt - Err: ${err} - Result: ${result}`;
    if (result) {
      setOwnerState((prevState) => ({
        ...prevState,
        isRandomArt: result,
      }));
    } else {
      setOwnerState((prevState) => ({
        ...prevState,
        isRandomArt: false,
      }));
      console.warn(consoleMessage);
    }
  };

  const getAirdropedAmount = async () => {
    let err, result;
    await info.contract.methods
      .getAirdropedAmount()
      .call({ from: info.account }, (e, r) => {
        err = e;
        result = r;
      });
    const consoleMessage = `getAirdropedAmount - Err: ${err} - Result: ${result}`;
    if (result) {
      setOwnerState((prevState) => ({
        ...prevState,
        airdropedAmount: result,
      }));
    } else {
      setOwnerState((prevState) => ({
        ...prevState,
        airdropedAmount: "0",
      }));
      console.warn(consoleMessage);
    }
  };

  const getMaximumAirdropAmount = async () => {
    let err, result;
    await info.contract.methods
      .getMaximumAirdropAmount()
      .call({ from: info.account }, (e, r) => {
        err = e;
        result = r;
      });
    const consoleMessage = `getMaximumAirdropeAmount - Err: ${err} - Result: ${result}`;
    if (result) {
      setOwnerState((prevState) => ({
        ...prevState,
        maximumAirdropAmount: result,
      }));
    } else {
      setOwnerState((prevState) => ({
        ...prevState,
        maximumAirdropAmount: "0",
      }));
      console.warn(consoleMessage);
    }
  };

  const getBaseURI = async () => {
    let err, result;
    await info.contract.methods
      .getBaseURI()
      .call({ from: info.account }, (e, r) => {
        err = e;
        result = r;
      });
    const consoleMessage = `getBaseURI - Err: ${err} - Result: ${result}`;
    if (result) {
      setOwnerState((prevState) => ({
        ...prevState,
        baseURI: result,
      }));
    } else {
      setOwnerState((prevState) => ({
        ...prevState,
        baseURI: "0",
      }));
      console.warn(consoleMessage);
    }
  };

  const getBalance = async () => {
    if (info.account !== ownerState.owner) return;
    let err, result;
    await info.contract.methods
      .getBalance()
      .call({ from: info.account }, (e, r) => {
        err = e;
        result = r;
      });
    const consoleMessage = `getBalance - Err: ${err} - Result: ${result}`;
    if (result) {
      setOwnerState((prevState) => ({
        ...prevState,
        balance: result,
      }));
    } else {
      setOwnerState((prevState) => ({
        ...prevState,
        balance: "0",
      }));
      console.warn(consoleMessage);
    }
  };

  

  const setBaseURI = async (baseURI) => {
    if (baseURI.length) {
      try {
        let err, result;
        const options = {
          to: info.contractJSON.address,
          from: info.account,
        };

        await info.contract.methods
          .setBaseURI(baseURI)
          .send(options, (e, r) => {
            err = e;
            result = r;
          });

        const consoleMessage = `setBaseURI - Err: ${err} - Result: ${result}`;
        console.log(consoleMessage);
        if (result === true || result === false) {
          getBaseURI();
        } else {
          setOwnerState((prevState) => ({
            ...prevState,
          }));
        }
      } catch (error) {
        setOwnerState((prevState) => ({
          ...prevState,
        }));
        console.error(error);
      }
    }
  };

  const isMatch = (a, b) => a === b;

  const getMagicWord = async () => {
    let err, result;
    await info.contract.methods
      .getMagicWord()
      .call({ from: info.account }, (e, r) => {
        err = e;
        result = r;
      });
    const consoleMessage = `getMagicWord - Err: ${err} - isMatchedMagicWord: ${isMatch(
      result,
      ownerState.keccak256MagicWord
    )} - getMagicWord: ${result} - magicWord: ${ownerState.keccak256MagicWord}`;
    if (result) {
      setOwnerState((prevState) => ({
        ...prevState,
        getMagicWord: result,
        isMatchedMagicWord: isMatch(result, prevState.keccak256MagicWord),
      }));
    } else {
      setOwnerState((prevState) => ({
        ...prevState,
        getMagicWord: "",
        isMatchedMagicWord: false,
      }));
      console.warn(consoleMessage);
    }
  };

  const runOwnableGetFunc = () => {
    getIsPaused();
    getIsRandomArt();
    getAirdropedAmount();
    getMaximumAirdropAmount();
    getBaseURI();
    getMagicWord();
    getBalance();
  };

  const mint = async (address) => {
    // mintBatch(address to, uint256 _mintAmount, string calldata _magicWord)
    let err, result;
    const options = {
      to: info.contractJSON.address,
      from: info.account,
      value:
        ownerState.owner === info.account
          ? "0"
          : String(
              info.web3.utils.toHex(Number(mintInfo.cost) * mintInfo.amount)
            ),
    };

    setMintInfo((prevState) => ({
      ...prevState,
      loading: true,
      status: `Minting ${mintInfo.amount}...`,
    }));

    await info.contract.methods

      .mintBatch(address || info.account, mintInfo.amount, ownerState.magicWord)
      .send(options, (e, r) => {
        err = e;
        result = r;
      });

    if (result) {
      setMintInfo((prevState) => ({
        ...prevState,
        loading: false,
        status: "You got a city ladies on your own wallet.",
      }));
      getSupply();
      getAirdropedAmount();
    } else {
      setMintInfo((prevState) => ({
        ...prevState,
        loading: false,
        status: "Fail to mint.",
      }));
      console.error(err);
    }
    // const params = {
    //   to: info.contractJSON.address,
    //   from: info.account,
    //   value: String(
    //     info.web3.utils.toHex(Number(mintInfo.cost) * mintInfo.amount)
    //   ),
    //   data: info.contract.methods
    //     .mintBatch(info.account, mintInfo.amount, magicWord)
    //     .encodeABI(),
    // };
    // try {
    //   setMintInfo((prevState) => ({
    //     ...prevState,
    //     loading: true,
    //     status: `Minting ${mintInfo.amount}...`,
    //   }));
    //   const txHash = await window.ethereum.request({
    //     method: "eth_sendTransaction",
    //     params: [params],
    //   });
    //   console.log(txHash);
    //   setMintInfo((prevState) => ({
    //     ...prevState,
    //     loading: false,
    //     status:
    //       "You send a transaction to network.",
    //   }));
    //   getSupply();
    // } catch (err) {
    //   setMintInfo((prevState) => ({
    //     ...prevState,
    //     loading: false,
    //     status: err.message,
    //   }));
    // }
  };

  const setIsPaused = async (_bool) => {
    try {
      let err, result;
      const options = {
        to: info.contractJSON.address,
        from: info.account,
      };

      await info.contract.methods.setIsPaused(_bool).send(options, (e, r) => {
        err = e;
        result = r;
      });
      const consoleMessage = `setIsPaused - Err: ${err} - Result: ${result}`;
      if (result) {
        getIsPaused();
      }
      console.warn(consoleMessage);
    } catch (error) {
      console.error(error);
      setOwnerState((prevState) => ({
        ...prevState,
      }));
    }
  };

  const setMaximumAirdropAmount = async (amount) => {
    if (amount > 0 && Number.isInteger(amount)) {
      try {
        let err, result;
        const options = {
          to: info.contractJSON.address,
          from: info.account,
        };

        await info.contract.methods
          .setMaximumAirdropAmount(amount)
          .send(options, (e, r) => {
            err = e;
            result = r;
          });

        const consoleMessage = `setMaximumAirdropAmount - Err: ${err} - Result: ${result}`;
        if (result) {
          getMaximumAirdropAmount();
        } else {
          setOwnerState((prevState) => ({
            ...prevState,
          }));
          console.warn(consoleMessage);
        }
      } catch (error) {
        setOwnerState((prevState) => ({
          ...prevState,
        }));
        console.error(error);
      }
    }
  };

  const setIsRandomArt = async (_bool) => {
    try {
      let err, result;
      const options = {
        to: info.contractJSON.address,
        from: info.account,
      };

      await info.contract.methods
        .setIsRandomArt(_bool)
        .send(options, (e, r) => {
          err = e;
          result = r;
        });

      const consoleMessage = `setIsRandomArt - Err: ${err} - Result: ${result}`;
      if (result) {
        getIsRandomArt();
      }
      console.warn(consoleMessage);
    } catch (error) {
      setOwnerState((prevState) => ({
        ...prevState,
      }));
      console.error(error);
    }
  };

  const withdraw = async (_bool) => {
    try {
      if (info.account !== ownerState.owner) return;
      let err, result;
      const options = {
        to: info.contractJSON.address,
        from: info.account,
      };

      await info.contract.methods
        .withdraw()
        .send(options, (e, r) => {
          err = e;
          result = r;
        });

      const consoleMessage = `withdraw - Err: ${err} - Result: ${result}`;
      if (result) {
        getBalance();
      }
      console.warn(consoleMessage);
    } catch (error) {
      setOwnerState((prevState) => ({
        ...prevState,
      }));
      console.error(error);
    }
  };

  const updateAmount = (newAmount) => {
    if (newAmount <= 5 && newAmount >= 1) {
      setMintInfo((prevState) => ({
        ...prevState,
        amount: newAmount,
      }));
    }
  };

  const connectToContract = (_contractJSON) => {
    init("eth_requestAccounts", _contractJSON);
  };

  useEffect(() => {
    connectToContract(contract);
    initListeners();
  }, []);

  useEffect(() => {
    if (info.connected) {
      getSupply();
      getPrice();
      getOwner();
      if (info.account === ownerState.owner) {
        runOwnableGetFunc();
      }
    }
  }, [info.connected, ownerState.owner]);

  return (
    <MinterWrapper>
      <div className="page">
        <div className="card">
          <div className="card_header colorGradient">
            <img
              className="card_header_image ns"
              alt={"banner"}
              src={imgSrc}
              style={{ maxWidth: "300px" }}
            />
          </div>
          {mintInfo.supply < contract.total_supply ? (
            <div className="card_body">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  disabled={!info.connected || mintInfo.cost === "0"}
                  className="small_button"
                  onClick={() => updateAmount(mintInfo.amount - 1)}
                >
                  -
                </button>
                <div style={{ width: 10 }} />
                <div style={{ color: "#b20000" }}>{mintInfo.amount}</div>
                <div style={{ width: 10 }} />
                <button
                  disabled={!info.connected || mintInfo.cost === "0"}
                  className="small_button"
                  onClick={() => updateAmount(mintInfo.amount + 1)}
                >
                  +
                </button>
                <button
                  disabled={!info.connected || mintInfo.cost === "0"}
                  className="button"
                  onClick={() => mint()}
                  style={{ marginLeft: "10px" }}
                >
                  Mint
                </button>
              </div>
              {info.connected ? (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ color: "#000000", textAlign: "center" }}>
                    {info.web3?.utils.fromWei(mintInfo.cost, "ether") *
                      mintInfo.amount}{" "}
                    {contract.chain_symbol}
                  </p>
                  <div style={{ width: 20 }} />
                  <p style={{ color: "#000000", textAlign: "center" }}>|</p>
                  <div style={{ width: 20 }} />
                  <p style={{ color: "#000000", textAlign: "center" }}>
                    {mintInfo.supply}/{contract.total_supply}
                  </p>
                </div>
              ) : null}
              {mintInfo.status ? (
                <p className="statusText">{mintInfo.status}</p>
              ) : null}
              {info.status ? (
                <p className="statusText" style={{ color: "var(--error)" }}>
                  {info.status}
                </p>
              ) : null}
            </div>
          ) : (
            <div className="card_body">
              <p style={{ color: "var(--statusText)", textAlign: "center" }}>
                {mintInfo.supply}/{contract.total_supply}
              </p>
              <p className="statusText">
                We've sold out! .You can still buy and trade the {contract.name}{" "}
                on marketplaces such as Opensea.
              </p>
            </div>
          )}
          <div className="card_footer colorGradient">
            <button
              className="button"
              style={{
                backgroundColor: info.connected
                  ? "var(--success)"
                  : "var(--warning)",
                color: "#ffffff",
              }}
              onClick={() => connectToContract(contract)}
            >
              {info.account ? "Connected" : "Connect Wallet"}
            </button>
            {info.connected ? (
              <span className="accountText">
                {String(info.account).substring(0, 6) +
                  "..." +
                  String(info.account).substring(38)}
              </span>
            ) : null}
          </div>
          <a
            style={{
              position: "absolute",
              bottom: 55,
              left: -75,
              color: "#ffffff",
            }}
            className="_90"
            target="_blank"
            rel="noreferrer"
            href={`https://etherscan.io/address/${contractAddress}`}
          >
            View Contract
          </a>
        </div>
        <div
          className={`backOffice ${
            ownerState.owner === info.account ? "owner" : "none"
          }`}
        >
          {ownerState.owner === info.account && (
            <BackOffice
              ownerState={ownerState}
              setIsPaused={setIsPaused}
              setIsRandomArt={setIsRandomArt}
              setMaximumAirdropAmount={setMaximumAirdropAmount}
              setBaseURI={setBaseURI}
              mint={mint}
              withdraw={withdraw}
            />
          )}
        </div>
      </div>
    </MinterWrapper>
  );
}

export default Minter;
