import { useEffect, useState } from "react";
import styled from "styled-components";

const ToggleWrapper = styled.div`
  .toggle {
    position: relative;
    top: 50%;
    width: 35px;
    height: 20px;
    overflow: hidden;
  }

  .toggle.r,
  .toggle.r .layer {
    border-radius: 100px;
    background-color: #fff;
  }

  .tg-checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }

  .knobs {
    z-index: 2;
  }

  .layer {
    width: 100%;
    background-color: #ebf7fc;
    transition: 0.3s ease all;
    z-index: 1;
  }

  .toggle .knobs:before,
  .toggle .knobs:after,
  .toggle .knobs span {
    position: absolute;
    top: 3px;
    width: 10px;
    height: 10px;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 2px 2px;
    border-radius: 50%;
    transition: 0.4s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
  }

  .toggle .knobs:before {
    content: "";
    left: 4px;
  }

  .toggle .knobs:after {
    content: "";
    right: 6px;
  }

  .toggle .knobs:before,
  .toggle .knobs:after {
    color: #fff;
    z-index: 2;
  }

  .toggle .knobs span {
    left: 4px;
    background-color: rgba(0, 128, 0, 0.3);
    z-index: 1;
  }

  .toggle .tg-checkbox:checked + .knobs:before {
    left: 4px;
  }

  .toggle .tg-checkbox:checked + .knobs:after {
    right: 4px;
  }

  .toggle .tg-checkbox:checked + .knobs span {
    left: 17px;
    background-color: rgba(0, 128, 0, 1);
  }

  .toggle .tg-checkbox:checked ~ .layer {
    background-color: #fcebeb;
  }
`;

const Toggle = ({ toggleState, toggleHandler }) => {
  const [curState, setCurState] = useState(false);

  const changedToggleValue = () => {
    try {
      if (toggleHandler) {
        toggleHandler(!curState);
        setCurState(toggleState);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setCurState(toggleState);
  }, [toggleState]);

  return (
    <ToggleWrapper>
      <div className="toggle r">
        <input
          type="checkbox"
          className="tg-checkbox"
          checked={curState}
          onChange={() => changedToggleValue()}
        />
        <div className="knobs">
          <span />
        </div>
        <div className="layer" />
      </div>
    </ToggleWrapper>
  );
};

export default Toggle;
