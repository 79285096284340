import styled from "styled-components";

const MinterWrapper = styled.div`
  .backOffice {
    transition: width 0.3s ease-in-out;
    &.owner {
      width: 380px;
    }
    &.none {
      width: 0px;
    }
  }
`;

export default MinterWrapper;
