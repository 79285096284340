import styled from "styled-components";

const BackOfficeWrapper = styled.div`
  color: white;
  .admin-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .admin-text {
    margin-right: 16px;
  }
  .row-item {
    height: 30px;
  }
  .col-item {
    flex-direction: column;
    .admin-text {
      margin-right: auto;
      width: 100%;
      margin: 8px auto;
    }
  }
  .item {
    display: inline-flex;
  }
  .admin-button {
    padding: 10px 0px;
    margin-left: auto;
    button {
      cursor: pointer;
    }
  }
  .isMatchedMagicWord {
    &.active {
      width: 10px;
      height: 10px;
      font-size: 10px;
      font-weight: bold;
      text-align: center;
      line-height: 1;
      padding: 2px 2px;
      border-radius: 50%;
      background-color: green;
    }
    &.none-match {
      background-color: #b20000;
    }
  }
`;

export default BackOfficeWrapper;
