import styled from "styled-components";

const AdminInputWrapper = styled.div`
  width: 100%;
  .inputBox {
    color: #333;
    transition: box-shadow 320ms;
    box-shadow: 0px 0px 1px 3px rgba(255, 255, 255, 0.4);
    border-radius: 3px;
    font-size: 15px;
    border: 0px;
    width: 100%;
    &:focus {
      outline: 0px;
      outline-offset: 0px;
      box-shadow: 0px 0px 1px 4px rgba(255, 255, 255, 0.7);
    }
  }
`;

const AdminInput = ({ type = "number", value, setValue }) => {
  return (
    <AdminInputWrapper>
      <input
        className="inputBox"
        type={type}
        value={value}
        onChange={(e) => {
          if (type === "number") {
            setValue(Number(e.target.value));
          }
          if (type === "text") {
            setValue(e.target.value);
          }
        }}
      />
    </AdminInputWrapper>
  );
};

export default AdminInput;
