import { useState, useEffect } from "react";
import { hexChecker, length42Checker, startWith0x } from "../utils/utils";

import AdminInput from "./AdminInput";
import BackOfficeWrapper from "./backOffice.style";
import Toggle from "./Toggle";

const BackOffice = ({
  ownerState,
  setIsPaused,
  setIsRandomArt,
  setMaximumAirdropAmount,
  // setBaseURI,
  mint,
  withdraw,
}) => {
  useEffect(() => {
    if (ownerState) {
      const maximumAirdropAmount = ownerState.maximumAirdropAmount;
      // const baseURI = ownerState.baseURI;
      if (maximumAirdropAmount && Number(maximumAirdropAmount)) {
        setMaxAirdropAmount(Number(maximumAirdropAmount));
      }
      // if (baseURI) {
      //   setAdminBaseURI(baseURI);
      // }
    }
  }, [ownerState]);

  const airdropedAmount = ownerState.airdropedAmount;

  const [maxAirdropAmount, setMaxAirdropAmount] = useState();
  // const [adminBaseURI, setAdminBaseURI] = useState();
  const [mintingAddress, setMintingAddress] = useState("");

  const handleClickMaximumAirdropAmount = () => {
    if (maxAirdropAmount > 0 && Number.isInteger(maxAirdropAmount)) {
      setMaximumAirdropAmount(maxAirdropAmount);
    }
  };

  // const handleClickAdminBaseURI = () => {
  //   if (adminBaseURI.length && adminBaseURI.startWidth("https://")) {
  //     setBaseURI(adminBaseURI);
  //   }
  // };

  const handleClickMintBatch = () => {
    const mintingAddressToLowerCase = mintingAddress.toLowerCase();
    if (
      mintingAddressToLowerCase.length > 0 &&
      length42Checker(mintingAddressToLowerCase) &&
      hexChecker(mintingAddressToLowerCase.slice(2)) &&
      startWith0x(mintingAddressToLowerCase)
    ) {
      mint(mintingAddressToLowerCase);
    }
  };

  const handleClickWithdraw = () => {
    const balance = ownerState.balance;
    if (Number(balance) > 0) {
      withdraw();
    }
  }

  return (
    <BackOfficeWrapper>
      <div className="admin-item row-item">
        <p className="admin-text">isPaused{"(일시정지)"}</p>
        <Toggle toggleState={ownerState.isPaused} toggleHandler={setIsPaused} />
      </div>
      <div className="admin-item row-item">
        <p className="admin-text">isRandomArt{"(리빌드)"}</p>
        <Toggle
          toggleState={ownerState.isRandomArt}
          toggleHandler={setIsRandomArt}
        />
      </div>

      <div className="admin-item">
        <p className="admin-text">airdropedAmount{"(에드된 총 물량)"}</p>
        <p className="admin-text">{airdropedAmount}</p>
      </div>

      <div className="admin-item col-item">
        <p className="admin-text">남은 에드 물량: {maxAirdropAmount - airdropedAmount}</p>
        <p className="admin-text">maximumAirdropAmount{"(에드할 수 있는 총 물량; 변경가능)"}</p>
        <AdminInput
          type={"number"}
          value={maxAirdropAmount || ""}
          setValue={setMaxAirdropAmount}
        />
        <div className="admin-button">
          <button onClick={handleClickMaximumAirdropAmount}>send</button>
        </div>
      </div>

      {/* <div className="admin-item col-item"> // 숨김
        <p className="admin-text">BaseURI</p>

        <AdminInput
          type={"text"}
          value={adminBaseURI}
          setValue={setAdminBaseURI}
        />
        <div className="admin-button">
          <button onClick={handleClickAdminBaseURI}>send</button>
        </div>
      </div> */}

      <div className="admin-item col-item">
        <p className="admin-text">Mint Batch{"(에어드랍 주소 입력)"}</p>

        <AdminInput
          type={"text"}
          value={mintingAddress}
          setValue={setMintingAddress}
        />
        <div className="admin-button">
          <button onClick={handleClickMintBatch}>send</button>
        </div>
      </div>

      <div className="admin-item">
        <p className="admin-text" style={{display: 'inline-block'}}>Balance: {ownerState.balance}</p>
          <button style={{padding: '2px 5px'}} className="admin-button" onClick={handleClickWithdraw}>withdraw</button>
      </div>

      <div className="admin-item item">
        <p className="admin-text">isMatchedMagicWord</p>
        <span
          className={`isMatchedMagicWord ${
            ownerState.isMatchedMagicWord ? "active" : "none-match"
          }`}
          data-hover={`${ownerState.isMatchedMagicWord ? "true" : "false"}`}
        />
      </div>
    </BackOfficeWrapper>
  );
};

export default BackOffice;
